<template>
  <div class="v-home">
    <div class="v-home__wrapper">
      <div class="v-home__box">
        <div
          v-if="courseDescription"
          class="v-home__description"
          v-md-html="courseDescription"
        />
        <nav>
          <ul class="v-home__buttons">
            <NavList :items="sections" />
            <li class="c-nav__item">
              <TheButton
                :href="tenant.docToolUrl || 'https://dokumentasjon.fagbokforlaget.no'"
                target="_blank"
                class="c-nav__link c-button"
              >
                {{ $t('NAV_DOCTOOL') }}
              </TheButton>
            </li>
            <NavList :items="teacherSections" />
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import NavList from '@/components/NavList'
import TheButton from '@/components/TheButton'
import useTranslations from '@/composables/useTranslations'

export default {
  name: 'Home',

  components: {
    NavList,
    TheButton,
  },

  setup() {
    const { tenant } = useTenant()
    const { locale, t } = useI18n()
    const { getTenantTranslation } = useTranslations()
    const courseDescription = computed(() =>
      getTenantTranslation(tenant.value.welcomeMessage),
    )
    const sections = computed(() =>
      getTenantTranslation(tenant.value.mainNav)?.filter(
        (n) => n.accessLevel !== 'TEACHER',
      ),
    )
    const teacherSections = computed(() =>
      getTenantTranslation(tenant.value.mainNav)?.filter(
        (n) => n.accessLevel === 'TEACHER',
      ),
    )

    useHead({
      title: `${t('NAV_HOME')} - ${getTenantTranslation(tenant.value.name)}`,
    })

    return {
      tenant,
      locale,
      courseDescription,
      sections,
      teacherSections,
    }
  },
}
</script>
